import React from 'react'
import StatisticsDashboard from '../../Components/adminComponents/Stats'

const Statistics = () => {
  return (

    <StatisticsDashboard/>

   
  )
}

export default Statistics