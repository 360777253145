import React, { useEffect, useState } from 'react';
import { PiCheckCircle } from "react-icons/pi";
import { useMongoUser } from '../context/mongoContext';
import { images, texts } from '../constants';

const NewUser = ({setChecker}) => {
  const { user, setUser, setShowClaimModal } = useMongoUser();
  const [currentCheck, setCurrentCheck] = useState(0);
  const [visibleChecks, setVisibleChecks] = useState(0);
  const [completedChecks, setCompletedChecks] = useState([]);
    // eslint-disable-next-line
  const [showRewards, setShowRewards] = useState(false);
  const [premiumRewards, setPremiumRewards] = useState(false);
  const [finishRewards, setFinishRewards] = useState(false);
  const [dailyCheckin, setDailyCheckin] = useState(false);
  const [awardedPoint, setAwardedPoint] = useState(0);
  // eslint-disable-next-line
  const [rewardProcessed, setRewardProcessed] = useState(false);
  const premiumPoints = 3000;
  const checkinPoint = 500;

// frontend/NewUser.js - Updated awardPoints function
const awardPoints = async () => {
  if (!user?.telegramId) return;

  // Check if user already has rewards
  if (user.yearsReward > 0 || (user.isPremium && user.premiumReward > 0)) {
    console.log('Rewards already claimed');
    return;
  }

  const pointsToAward = 2000; // Fixed value
  const newBalance = user.isPremium 
    ? pointsToAward + checkinPoint + premiumPoints
    : pointsToAward + checkinPoint;

  try {
    const now = new Date();
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    
    const endpoint = user.isPremium 
      ? '/api/new-premium-user-bonus'
      : '/api/new-user-bonus';

    const payload = {
      telegramId: user.telegramId,
      balance: newBalance,
      yearsReward: pointsToAward,
      checkinRewards: checkinPoint,
      checkInDays: [1],
      lastCheckIn: now.toISOString(),
      ...(user.isPremium && { premiumReward: premiumPoints }),
    };

    const response = await fetch(`${SERVER_URL}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json();
    
    if (!response.ok) {
      throw new Error(data.message || 'Failed to save ton task');
    }

    if (data.message === 'Rewards already claimed') {
      console.log('Rewards were already claimed');
      return;
    }

    setUser(prevUser => ({
      ...prevUser,
      balance: data.data.balance,
      lastCheckIn: data.data.lastCheckIn,
      checkinRewards: data.data.checkinRewards,
      yearsReward: data.data.yearsReward,
      checkInDays: data.data.checkInDays,
      ...(user.isPremium && { premiumReward: data.data.premiumReward }),
    }));

    setAwardedPoint(pointsToAward);
    setShowClaimModal(false);
  } catch (error) {
    console.error('Error updating user points:', error);
  }
};

  useEffect(() => {
    if (user.telegramId && !rewardProcessed) {
      awardPoints();
    }
  // eslint-disable-next-line
  }, [user.telegramId]);

  useEffect(() => {
    if (visibleChecks < 4) {
      const timer = setTimeout(() => {
        setVisibleChecks(visibleChecks + 1);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [visibleChecks]);

  useEffect(() => {
    if (currentCheck < 4 && currentCheck < visibleChecks) {
      const timer = setTimeout(() => {
        setCurrentCheck(currentCheck + 1);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [currentCheck, visibleChecks]);

  const handleTransitionEnd = (index) => {
    setCompletedChecks((prev) => [...prev, index]);
  };

  const allChecksCompleted = completedChecks.length === 4;

  const openCollect = () => {
    setPremiumRewards(true);
    setShowRewards(false);
  }

  const openPremium = () => {
    setFinishRewards(true);
    setPremiumRewards(false);
  }

  const openFinish = () => {
    setDailyCheckin(true);
    setFinishRewards(false);
  }

  const openDone = () => {
    setChecker(false);
    setDailyCheckin(false);
  }

  const formatNumber = (number) => {
    if (number === undefined || number === null || isNaN(number)) {
      return '';
    }
  
    if (number >= 1000000) {
      return (number / 1000000).toFixed() + 'M';
    } else if (number >= 100000) {
      return (number / 1000).toFixed(0) + 'K';
    } else {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
  };


  return (
    <>

    <div className='w-full h-full relative pb-24 flex items-center justify-center flex-col space-y-20'>



    <div className='font-bold checking w-full absolute top-8 text-center text-[#fff]'>
                Checking your account
              </div>
    
    
            <div className='flex flex-col w-full justify-start items-center px-1 space-y-16'>
    
    
     
            <div className="flex flex-col items-start !mt-[-100px] space-y-8 w-full">
      {['Account Age Verified', 'Activity Level Analyzed', 'Telegram Premium Checked', 'OG Status Confirmed'].map((check, index) => (
        <div key={index} className={`flex flex-col items-center gap-2 w-full transition-opacity duration-500 ${index < visibleChecks ? 'opacity-100' : 'opacity-0'}`}>
          <div className='w-full flex justify-between items-center'>
            <div className='text-[16px] font-semibold'>{check}</div>
            <PiCheckCircle size={20} className={`${completedChecks.includes(index) ? 'text-accent' : 'text-white'}`}/>
          </div>
          <div className="w-full h-2 bg-gray-300 rounded-[2px] relative overflow-hidden">
            <div
              className={`h-full bg-btn ${currentCheck > index ? 'w-full' : 'w-0'} transition-all duration-[2000ms]`}
              onTransitionEnd={() => handleTransitionEnd(index)}
            ></div>
          </div>
        </div>
      ))}

    </div>

    {allChecksCompleted && (
      <>
      {user.isPremium ? (
              <button onClick={openCollect} className={`w-full py-3 rounded-[8px] absolute bottom-10 font-semibold px-3 items-center justify-center text-center bg-btn`}>
              Continue
             </button>
      ) : (
        <button onClick={openPremium} className={`w-full py-3 rounded-[8px] absolute bottom-10 font-semibold px-3 items-center justify-center text-center bg-btn`}>
        Continue
       </button>
      )}

      </>
                  )}

    
    
            </div>
    
            </div>

{/* {showRewards && (
      
    <div className="fixed left-0 right-0 z-20 top-[-12px] bottom-0 flex bg-[#000] justify-center px-[16px] h-full">

<div className='backdrop-blur-[1px] absolute top-0 left-0 right-0 bottom-0'>

</div>

    <div className="w-full pt-10 justify-center flex-col items-center space-y-4 relative">
  <div className='w-full flex justify-center space-x-2'>

    <div className={`w-[33%] h-[6px] rounded-[50px] ${showRewards ? 'bg-btn' : 'bg-btn4 opacity-[16%]'}`}></div>
    <div className={`w-[33%] h-[6px] rounded-[50px] ${premiumRewards ? 'bg-btn' : 'bg-btn4 opacity-[16%]'}`}></div>
    <div className={`w-[33%] h-[6px] rounded-[50px] ${finishRewards ? 'bg-btn' : 'bg-btn4 opacity-[16%]'}`}></div>

  </div>
  <div className='w-full absolute'>
        <img src='/congrats2.gif' alt='cong' className='w-full'/>
    </div>

  <div className='w-full flex flex-col space-y-5 items-center'>

    <h2 className='font-bold text-[30px]'>
        Gold!
    </h2>

    <p className='text-[16px] font-semibold pb-6'>
        You've joined Telegram
    </p>

    <h2 className='font-bold text-[100px] leading-[100px] !p-0 !m-0'>
      {years !== 'Unknown' ? `${years}` : 'Unknown'}
    </h2>
    <p className='text-[20px] text-white font-bold !mt-0'>
    {displayText} ago
    </p>

    <div className={``}>
      <img src='/gold.svg' alt='sdf' className='w-[180px]'/>
    </div>

  </div>



    </div>

    <div className='w-full absolute bottom-6 flex px-4'>
        <div className='w-full flex flex-col items-center space-y-4 text-center'>

  
        <p className='max-w-[28em] textlow text-white font-semibold'>
        
            You're in the Top 90% Telegram users 🔥
        </p>
        <button onClick={openCollect} className={`w-full py-3 rounded-[8px] font-semibold px-3 items-center justify-center text-center bg-[#fff] text-[#000]`}>
              Continue
             </button>
             </div>
    </div>
    </div>
)}  */}

{user.isPremium && (
<>
{premiumRewards && (
      
      <div className="fixed left-0 right-0 z-20 top-[-12px] bottom-0 flex bg-[#000] justify-center px-[16px] h-full">
  
  <div className='absolute top-0 left-0 right-0 bottom-0'>
  
  </div>
  
      <div className="w-full pt-10 justify-center flex-col items-center space-y-4 relative">
    <div className='w-full flex justify-center space-x-2'>
  
      <div className={`w-[43%] h-[6px] rounded-[50px] ${premiumRewards ? 'bg-btn' : 'bg-btn4 opacity-[16%]'}`}></div>
      <div className={`w-[43%] h-[6px] rounded-[50px] ${finishRewards ? 'bg-btn' : 'bg-btn4 opacity-[16%]'}`}></div>
  
    </div>
    <div className='w-full absolute'>
          <img src='/congrats2.gif' alt='cong' className='w-full'/>
      </div>
  
    <div className='w-full flex flex-col space-y-5 items-center'>
  
      <h2 className='font-bold text-[30px] text-center'>
         Telegram Premium
      </h2>
  
      <p className='text-[16px] font-semibold pb-6'>
          You are one of the best!
      </p>
  
      <div className={``}>
        <img src='/prem.svg' alt='sdf' className='w-[280px] premiumsvg'/>
      </div>
  
      <p className='text-[20px] text-white font-bold !mt-0'>
     Premium user
      </p>
  
  
    </div>
  
  
  
      </div>
  
      <div className='w-full absolute bottom-7 flex px-4'>
          <div className='w-full flex flex-col items-center space-y-4 text-center'>
  
    
          <p className='max-w-[28em] textlow text-white font-semibold'>
          
              Status confirmed
          </p>
          <button onClick={openPremium} className={`w-full py-3 rounded-[8px] font-semibold px-3 items-center justify-center text-center bg-[#fff] text-[#000]`}>
                Continue
               </button>
               </div>
      </div>
      </div>
  )} 
</>
)}


{finishRewards && (
      
      <div className="fixed left-0 right-0 z-20 top-[-12px] bottom-0 flex bg-[#000] justify-center px-[16px] h-full">
  
  <div className='absolute top-0 left-0 right-0 bottom-0'>
  
  </div>
  
      <div className="w-full pt-10 justify-center flex-col items-center space-y-4 relative">
    <div className='w-full flex justify-center space-x-2'>

      <div className={`${user.isPremium ? '' : 'hidden'} w-[43%] h-[6px] rounded-[50px] ${!premiumRewards ? 'bg-btn' : 'bg-btn4 opacity-[16%]'}`}></div>
      <div className={`${user.isPremium ? 'w-[43%] ' : 'w-[86%]'} h-[6px] rounded-[50px] ${finishRewards ? 'bg-btn' : 'bg-btn4 opacity-[16%]'}`}></div>
  
    </div>
  
    <div className='w-full flex flex-col space-y-5 items-center'>
  
      <h2 className='font-bold text-[30px] text-center w-full'>
        {user.isPremium ? 'You are the best' : `Welcome to ${texts.projectName}`}
      </h2>
  
      <p className='text-[16px] font-semibold pb-14'>
        Here is your {texts.projectName} rewards
      </p>
  
      <div className={``}>
        <img src={images.logo} alt='sdf' className='w-[80px] premiumsvg'/>
      </div>
  
      <p className='text-[24px] text-white font-bold !mt-3'>
        {user.isPremium ? (
          <>
                    +{formatNumber(awardedPoint + premiumPoints)}
          </>
        ) : (
          <>
          +{formatNumber(awardedPoint)}
</>
        )}
  
      </p>
  
      <p className='text-[16px] !mt-0'>
     {texts.projectName}
      </p>
  
  
    </div>
  
  
  
      </div>
  
      <div className='w-full absolute bottom-7 flex px-4'>
          <div className='w-full flex flex-col items-center space-y-4 text-center'>
  
    
          <p className='max-w-[28em] textlow text-white font-semibold'>
          
             {texts.projectName} up your earnings - Mine crypto now!
          </p>
          <button onClick={openFinish} className={`w-full py-3 rounded-[8px] font-semibold px-3 items-center justify-center text-center bg-[#fff] text-[#000]`}>
                Continue
               </button>
               </div>
      </div>
      </div>
  )} 



{dailyCheckin && (
      
    <div className="fixed left-0 right-0 z-20 top-[-12px] bottom-0 flex bg-[#000] justify-center px-[16px] h-full">

<div className='absolute top-0 left-0 right-0 bottom-0'>

</div>

    <div className="w-full pt-10 justify-center flex-col items-center space-y-4 relative">


  <div className='w-full flex flex-col space-y-5 items-center pt-16'>

    <h2 className='font-bold text-[42px]'>
      Day 1
    </h2>
    <p className='text-[20px] text-white font-bold !mt-1'>
Daily Checkin
    </p>

    <p className='text-[16px] text-white !mt-1'>
  +{formatNumber(checkinPoint)} {texts.projectName}
    </p>

    <div className={``}>
      <img src='/checkin.svg' alt='sdf' className='w-[150px]'/>
    </div>

  </div>



    </div>

    <div className='w-full absolute bottom-7 flex px-4'>
        <div className='w-full flex flex-col items-center space-y-4 text-center'>

  
        <p className='max-w-[28em] textlow text-white font-medium px-1'>
        
          Visit daily to maintain your streak! if you miss a day you loose your progress and start afresh😎
        </p>
        <button onClick={openDone} className={`w-full py-3 rounded-[8px] font-semibold px-3 items-center justify-center text-center bg-btn`}>
              Continue
             </button>
             </div>
    </div>
    </div>
)} 
</>

  );
};

export default NewUser;
