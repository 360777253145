import React, { useState, useEffect, useCallback } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useMongoUser } from '../context/mongoContext';
import { TonConnectButton, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import { IoIosAlert, IoIosLogOut, IoMdClose } from "react-icons/io";
import { TbHandClick, TbPlaceholder } from "react-icons/tb";
import { SlEnergy } from "react-icons/sl";
import { RiThunderstormsFill } from "react-icons/ri";
import { FaPlay } from 'react-icons/fa6';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { texts } from '../constants';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const HOLD_DURATION = 15; // seconds
const BASE_HOLD_REWARD = 500; // Base reward for Level 1
const MAX_DAILY_ATTEMPTS = 3; // Maximum daily hold attempts
const GAME_LIMIT_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const AD_COOLDOWN_DURATION = 2 * 60 * 60 * 1000; // 2 hours in milliseconds

const HoldPowers = [
  {
    title: "Level 2",
    capacity: 500,
    cost: '400000000',
    price: 0.4,
  },
  {
    title: "Level 3",
    capacity: 1000,
    cost: '700000000',
    price: 0.7,
  },
  {
    title: "Level 4",
    capacity: 1500,
    cost: '1000000000',
    price: 1.0,
  },
];

const progressAnimation = `
@keyframes progress {
  0% {
    stroke-dasharray: 0 251.32;
  }
  100% {
    stroke-dasharray: 251.32 0;
  }
}
.animate-progress {
  animation: progress ${HOLD_DURATION}s linear forwards;
}
`;

function checkGameLimit() {
  const gameLimit = localStorage.getItem('gameLimit');
  if (gameLimit) {
    const { timestamp, count } = JSON.parse(gameLimit);
    if (Date.now() - timestamp < GAME_LIMIT_DURATION) {
      return count >= MAX_DAILY_ATTEMPTS;
    }
  }
  return false;
}

function recordAttempt() {
  const gameLimit = localStorage.getItem('gameLimit');
  const current = gameLimit ? JSON.parse(gameLimit) : { timestamp: Date.now(), count: 0 };

  if (Date.now() - current.timestamp >= GAME_LIMIT_DURATION) {
    current.timestamp = Date.now();
    current.count = 1;
  } else {
    current.count += 1;
  }

  localStorage.setItem('gameLimit', JSON.stringify(current));
}

function getAttemptsLeft() {
  const gameLimit = localStorage.getItem('gameLimit');
  if (gameLimit) {
    const { timestamp, count } = JSON.parse(gameLimit);
    if (Date.now() - timestamp < GAME_LIMIT_DURATION) {
      return MAX_DAILY_ATTEMPTS - count;
    }
  }
  return MAX_DAILY_ATTEMPTS;
}

export default function HoldToEarn() {
  const [isHolding, setIsHolding] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [attemptsLeft, setAttemptsLeft] = useState(getAttemptsLeft());
  const [totalBalance, setTotalBalance] = useState(() => {
    return parseInt(localStorage.getItem('totalBalance') || '0');
  });
  const [holdSound, setHoldSound] = useState(null);
  const [countdown, setCountdown] = useState(HOLD_DURATION);
  const [showPowerModal, setShowPowerModal] = useState(false);
  const controls = useAnimation();
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const navigate = useNavigate();
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [limitResetTime, setLimitResetTime] = useState(0);
  const [adCooldown, setAdCooldown] = useState(null);
  const [remainingTime, setRemainingTime] = useState("");
  const [currentLevel, setCurrentLevel] = useState(1);
  const [nextLevel, setNextLevel] = useState(HoldPowers[0]);
  const wallet = useTonWallet();
  const [tonConnectUI] = useTonConnectUI();
  const { user, setUser } = useMongoUser();

  const calculateHoldReward = () => {
    return BASE_HOLD_REWARD + (currentLevel - 1) * 500; // Dynamic reward based on level
  };

  const HOLD_REWARD = calculateHoldReward();

  const handleExit = () => {
    setIsExitModalOpen(true);
  };

  const confirmExit = () => {
    navigate('/earn');
  };

  const cancelExit = () => {
    setIsExitModalOpen(false);
  };

  useEffect(() => {
    const audio = new Audio('sounds/hold.mp3');
    audio.addEventListener('canplaythrough', () => {
      setHoldSound(audio);
    });
    audio.addEventListener('error', (e) => {
      console.error("Error loading audio:", e);
      setHoldSound(null);
    });
  }, []);

  useEffect(() => {
    if (isHolding) {
      controls.start({
        rotate: 360,
        transition: {
          duration: HOLD_DURATION,
          ease: "linear",
          repeat: Infinity,
        },
      });
    } else {
      controls.stop();
      controls.set({ rotate: 0 });
    }
  }, [isHolding, controls]);

  useEffect(() => {
    if (isHolding && holdSound) {
      holdSound.play().catch((e) => console.error("Error playing sound:", e));
    } else if (holdSound) {
      holdSound.pause();
      holdSound.currentTime = 0;
    }
  }, [isHolding, holdSound]);

  useEffect(() => {
    if (isHolding) {
      const startTime = Date.now();
      const timer = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const remainingTime = Math.max(0, HOLD_DURATION - Math.floor(elapsedTime / 1000));
        setCountdown(remainingTime);

        if (remainingTime === 0) {
          clearInterval(timer);
          setIsHolding(false);
          setShowSuccess(true);
          recordAttempt();
          setAttemptsLeft(getAttemptsLeft());
          const newBalance = totalBalance + HOLD_REWARD;
          setTotalBalance(newBalance);
          localStorage.setItem('totalBalance', newBalance.toString());

          // Update user balance in the database
          updateUserBalance(newBalance);
        }
      }, 100);

      return () => {
        clearInterval(timer);
        setCountdown(HOLD_DURATION);
      };
    } else {
      const circle = document.querySelector('circle');
      if (circle) {
        circle.style.animation = 'none';
        void circle.offsetHeight;
        circle.style.animation = null;
      }
    }
  }, [isHolding, totalBalance, HOLD_REWARD]);

  const handleStart = useCallback(() => {
    if (checkGameLimit()) {
      const gameLimit = JSON.parse(localStorage.getItem('gameLimit'));
      const resetTime = gameLimit.timestamp + GAME_LIMIT_DURATION - Date.now();
      setLimitResetTime(Math.ceil(resetTime / 1000));
      setShowLimitModal(true);
    } else if (!isHolding) {
      setIsHolding(true);
      if (holdSound) {
        holdSound.play().catch((e) => console.error("Error playing sound:", e));
      }
    }
  }, [isHolding, holdSound]);

  const handleEnd = () => {
    if (isHolding) {
      setIsHolding(false);
      if (holdSound) {
        holdSound.pause();
        holdSound.currentTime = 0;
      }
    }
  };

  const handlePowerClick = () => {
    setShowPowerModal(true);
  };

  const handleUpgrade = async () => {
    if (!wallet) {
      await tonConnectUI.connect();
      return;
    }

    try {
      const response = await tonConnectUI.sendTransaction({
        validUntil: Math.floor(Date.now() / 1000) + 300,
        messages: [
          {
            address: process.env.REACT_APP_WALLET_ADDRESS,
            amount: nextLevel.cost,
          },
        ],
      });

      if (response) {
        const newLevel = currentLevel + 1;
        setCurrentLevel(newLevel);
        setNextLevel(HoldPowers[newLevel - 1]);

        // Update user's hold power in the database
        await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/update-hold-power`, {
          telegramId: user.telegramId,
          holdPower: nextLevel.capacity,
        });

        setShowPowerModal(false);
      }
    } catch (error) {
      console.error("Upgrade error:", error);
      alert("Transaction failed. Please try again.");
    }
  };

  const handleWatchAd = () => {
    if (window.show_8683170) {
      window.show_8683170(() => {
        // Ad watched successfully
        setAdCooldown(AD_COOLDOWN_DURATION);
        setAttemptsLeft(1);
        setShowLimitModal(false);

        // Start the cooldown timer
        const intervalId = setInterval(() => {
          setAdCooldown((prevTime) => {
            if (prevTime <= 1000) {
              clearInterval(intervalId);
              return null;
            }
            return prevTime - 1000;
          });
        }, 1000);
      });
    } else {
      console.error("Ad service not available.");
    }
  };

  const updateUserBalance = async (newBalance) => {
    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/update-balance`, {
        telegramId: user.telegramId,
        balance: newBalance,
      });
    } catch (error) {
      console.error("Error updating balance:", error);
    }
  };

  useEffect(() => {
    let timer;
    if (showLimitModal && limitResetTime > 0) {
      timer = setInterval(() => {
        setLimitResetTime((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setShowLimitModal(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [showLimitModal, limitResetTime]);

  return (
    <>
      <style>{progressAnimation}</style>
      {isExitModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
          <div className="bg-[#3b3a70] text-white px-6 py-4 rounded-lg shadow-lg flex flex-col items-center justify-center space-y-2">
            <IoIosLogOut size={30} />
            <span className="font-medium text-[15px] w-full text-center pb-2">Are you sure you want to exit?</span>
            <div className="w-full flex justify-center space-x-4 items-center">
              <button
                onClick={confirmExit}
                className="rounded-lg py-2 px-3 bg-[#c586e9] text-[#fff] text-center font-medium text-[15px] w-[45%]"
              >
                Yes, Exit
              </button>
              <button
                onClick={cancelExit}
                className="rounded-lg py-2 px-3 bg-white/70 text-black font-medium text-center text-[15px] w-[45%]"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {showPowerModal && (
        <div className="fixed flex bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#303030c4] flex-col justify-end items-center">
          <div className="w-full bg-divider shadowtop rounded-tl-[40px] rounded-tr-[40px] relative flex flex-col ease-in duration-300 transition-all justify-center">
            <div className="w-full flex taskbg rounded-tl-[40px] rounded-tr-[40px] mt-[2px] justify-center relative flex-col items-center space-y-3 p-4 pt-20 pb-10">
              <button
                onClick={() => setShowPowerModal(false)}
                className="flex items-center justify-center h-[32px] w-[32px] rounded-full bg-[#383838] absolute right-6 top-4 text-center font-medium text-[16px]"
              >
                <IoMdClose size={20} className="text-[#9995a4]" />
              </button>

              <div className="bg-yellow-500 rounded-lg p-2 mb-4">
                <RiThunderstormsFill className="text-2xl" />
              </div>

              <h2 className="text-2xl font-bold mb-2">Hold Power Level</h2>
              <p className="text-gray-400 text-center text-sm mb-8">
                Increase the amount of coins you earn on each hold of the Buff coin
              </p>

              <div className="w-full space-y-4 mb-8">
                <div className="w-full bg-cards py-4 px-4 rounded-[8px] flex justify-between items-center">
                  <span className="text-gray-400 text-[14px]">Current Hold Power</span>
                  <span className="text-[13px] font-semibold">{HOLD_REWARD} hp</span>
                </div>

                <div className="w-full bg-cards py-4 px-4 rounded-[8px] flex justify-between items-center">
                  <span className="text-gray-400 text-[14px]">Next {nextLevel.title} Hp</span>
                  <span className="text-[13px] font-semibold text-accent">+{nextLevel.capacity} Hp</span>
                </div>

                <div className="w-full bg-cards py-4 px-4 rounded-[8px] flex justify-between items-center">
                  <span className="text-gray-400 text-[14px]">Hp {nextLevel.title} Cost</span>
                  <span className="flex items-center">
                    <span className="pl-1">
                      <img src="ton.png" alt="ton" className="w-[12px] h-[12px] mr-2" />
                    </span>
                    <span className="text-[13px] font-semibold">{nextLevel.price} TON</span>
                  </span>
                </div>
              </div>

              <button
                onClick={handleUpgrade}
                className="bg-btn text-[#000] w-full py-[18px] px-6 text-nowrap flex items-center justify-center text-center rounded-[12px] font-semibold text-[17px]"
              >
                {wallet ? `Upgrade to ${nextLevel.title}` : "Connect Wallet"}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="min-h-screen bg-black text-white pt-4">
        {/* Header */}
        <div className="w-full px-6">
          <div className="w-full flex justify-between">
            <div className="w-fit mt-[-4px] px-4 py-1.5 border border-[#7d80a8cc] bg-[#635e96ac] rounded-xl flex justify-center items-center space-x-2">
              <img src="/dogs.webp" alt="logo" className="w-[14px]" />
              <span className="font-semibold text-[14px]">{totalBalance}</span>
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={handleExit}
                className="w-fit mt-[-4px] px-1.5 py-[8px] border border-[#7d80a8cc] bg-[#635e96ac]/30 rounded-xl flex justify-center items-center space-x-1"
              >
                <IoIosLogOut />
                <span className="font-semibold text-[12px]">Exit</span>
              </button>
            </div>
          </div>
          <div className="w-full flex items-center justify-between border-[#382f64] border-[1px] py-2 px-3 mt-6 rounded-[10px] bg-[#332669]">
            <div className="bg-gradient-to-r from-[#fa8bd9] to-[#6a95fe] rounded-[8px] text-primary py-[16px] px-3 w-full flex space-x-1 justify-center text-center text-[13px] font-medium items-center">
              <span>Hold to Earn Game</span>
            </div>
          </div>
        </div>

        {/* Game Stats */}
        <div className="w-full flex justify-between px-6 mt-4 items-center">
          <button
            onClick={handlePowerClick}
            className="bg-[#3e3071] border-[1px] border-[#493e7a] rounded-[8px] text-primary py-[10px] px-4 w-fit space-x-1.5 text-[13px] font-medium flex justify-center text-center items-center"
          >
            <span className="mr-2 flex items-center text-white">
              <SlEnergy className="text-yellow-400 mr-1" />
              {HOLD_REWARD} hp
              <IoIosAlert className="text-gray-400 ml-1" />
            </span>
          </button>
          <button
            className="bg-[#c586e999] border-[1px] border-[#493e7a] rounded-[8px] text-primary py-[10px] px-4 w-fit space-x-1.5 text-[13px] font-medium flex justify-center text-center items-center"
          >
            <span className="mr-2 flex items-center text-white">
              <TbHandClick className="text-yellow-400" /> ({attemptsLeft}) holds left
            </span>
          </button>
        </div>

        {/* Game Area */}
        <div className="flex flex-col items-center">
          {/* Instruction or Countdown */}
          <div className="mb-4 mt-4 text-sm">
            {isHolding ? `Hold for ${countdown}s` : 'Hold to mine token'}
          </div>

          <div className="relative w-60 h-60">
            {/* Circular Progress */}
            <svg className="absolute inset-0 w-full h-full" viewBox="0 0 100 100">
              <circle
                cx="50"
                cy="50"
                r="40"
                fill="none"
                stroke="#4B0082"
                strokeWidth="20"
                strokeDasharray="251.32"
                strokeDashoffset="251.32"
                transform="rotate(-90 50 50)"
                className={isHolding ? 'animate-progress' : ''}
                style={{
                  strokeDashoffset: isHolding ? 0 : 251.32,
                  transition: isHolding ? 'none' : 'stroke-dashoffset 0.1s linear',
                }}
              />
            </svg>

            {/* Clickable Image */}
            <div
              className="absolute inset-[10px] rounded-full overflow-hidden cursor-pointer"
              onPointerDown={handleStart}
              onPointerUp={handleEnd}
              onPointerLeave={handleEnd}
            >
              <img src="/dogs.webp" alt="Game Logo" className="w-full h-full object-cover rounded-full" />
            </div>
          </div>
        </div>

        <div className="w-full">
          <p className="text-center mt-12 px-7 text-sm font-medium">
            Hold to earn Buff Coins. You have a free Hold every 24 hours.
            Watch ads to get one more hold every hour.
          </p>
        </div>

        {/* Success Modal */}
        {showSuccess && (
          <div className="fixed inset-0 bg-black bg-opacity-80 px-6 flex items-center justify-center z-50">
            <div className="bg-[#303030] p-6 rounded-lg shadow-xl flex justify-center flex-col items-center">
              <div className="w-full items-center justify-center flex flex-col space-y-2">
                <IoCheckmarkCircleSharp size={32} className="text-accent" />
                <p className="font-medium">Congratulations!</p>
              </div>
              <h3 className="font-medium text-[24px] text-[#ffffff] pt-2 pb-2">
                <span className="text-accent">+ {HOLD_REWARD}</span> {texts.projectSymbol}
              </h3>
              <p className="pb-6 text-[#bfbfbf] text-[15px] w-full text-center">
                Keep playing! Something huge is coming! Get more {texts.projectSymbol} now!
              </p>
              <div className="w-full flex justify-center">
                <button
                  onClick={() => setShowSuccess(false)}
                  className="bg-btn text-[#000] w-fit py-[10px] px-6 flex items-center justify-center text-center rounded-[12px] font-medium text-[16px]"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Game Limit Modal */}
        {showLimitModal && (
          <div className="fixed flex bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#303030c4] flex-col justify-end items-center">
            <div className="w-full bg-divider shadowtop rounded-tl-[40px] rounded-tr-[40px] relative flex flex-col ease-in duration-300 transition-all justify-center">
              <div className="w-full flex taskbg rounded-tl-[40px] rounded-tr-[40px] mt-[2px] justify-center relative flex-col items-center space-y-3 p-4 pt-20 pb-10">
                <button
                  onClick={() => setShowLimitModal(false)}
                  className="flex items-center justify-center h-[32px] w-[32px] rounded-full bg-[#383838] absolute right-6 top-4 text-center font-medium text-[16px]"
                >
                  <IoMdClose size={20} className="text-[#9995a4]" />
                </button>

                <div className="w-full bg-cards rounded-[16px] py-6 relative px-4 flex flex-col justify-center items-center">
                  <TbPlaceholder size={34} className="text-accent mb-4" />

                  <p className="text-[#e5e5e5] font-medium px-8 text-[14px] w-full text-center">
                    Your next free hold starts in
                  </p>
                  <span className="text-[34px] font-semibold">
                    {Math.floor(limitResetTime / 3600)}h {Math.floor((limitResetTime % 3600) / 60)}m {limitResetTime % 60}s
                  </span>
                </div>

                <div className="w-full flex justify-between items-center gap-2 px-4">
                  <div className="w-[40%] h-[2px] bg-cards2"></div>
                  <span className="text-nowrap">OR</span>
                  <div className="w-[40%] h-[2px] bg-cards2"></div>
                </div>

                <div className="w-full flex flex-col justify-center items-center">
                  <h3 className="font-semibold text-[24px]">Watch Ad</h3>
                  <p className="text-[14px] font-medium">
                    & get one more free Hold for more rewards
                  </p>
                </div>

                <div className="w-full flex justify-center items-center flex-col space-y-4">
                  <button
                    onClick={handleWatchAd}
                    className="w-full bg-btn py-[18px] px-6 text-nowrap flex items-center justify-center gap-2 text-center rounded-[12px] font-semibold text-[17px]"
                  >
                    <span className="contents">
                      <FaPlay /> Watch Ad for free hold
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}