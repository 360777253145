import React from 'react'
import AdminSettings from '../../Components/adminComponents/AdminSettings'


const Settings = () => {
  return (
   <AdminSettings/>
  )
}

export default Settings