import React, { useState } from 'react';
import { useMongoUser } from '../context/mongoContext'
import { NavLink } from 'react-router-dom'
import VideoTasksMenu from '../Components/VideoTasksMenu';
import PartnersTasksMenu from '../Components/PartnersTasksMenu';
import RefRewardsTasksMenu from '../Components/RefRewardsTasksMenu';
import WatchAdsTask from '../Components/WatchAdsTask.js';
import WatchAdsGramTask from '../Components/WatchAdsGramTask.js';
import { MdLocationPin,MdAdsClick } from 'react-icons/md'
import {FaPuzzlePiece} from "react-icons/fa";
import { texts } from '../constants'
import {IoClose } from 'react-icons/io5';
import { TbPlaceholder } from "react-icons/tb";

const Earns = () => {
    const {user} = useMongoUser()
    const [activeTab, setActiveTab] = useState('Games');
    const [ambsprogram, setAmbsProgram] = useState(false);
    const tabs = ['Games', 'Videos', 'Partners', 'Ref Rewards'];

  return (
    <div className="p-4">
    <div className="w-full flex justify-center">
      <h1 className="font-semibold text-[28px] text-[#ffffffe0] pb-1 text-center">
        Play games to <br/>earn more {texts.projectSymbol}
      </h1>
    </div>
    <div className="flex border-b border-gray-200 mt-4">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={`px-3 py-2 text-sm font-medium border-b-2 focus:outline-none transition-colors duration-200
              ${activeTab === tab ? 'border-blue-500 text-blue-500' : 'border-transparent text-gray-500 hover:text-blue-500'}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      <div className="mt-4">
        {activeTab === 'Games' && (
        <div id="refer" className="w-full h-[70vh] scroller overflow-y-auto pb-[70px] space-y-3">
          <div className="w-full bg-cards rounded-[16px] px-3 py-3 flex items-center justify-between space-x-1">           
          <div className='flex items-center justify-center bg-[#1f2023] h-[50px] w-[50px mr-1 rounded-full p-1'>
          <div className='w-[40px] h-[40px] fanbg border-[#616161] border-[2px] flex justify-center rounded-full items-center text-center relative'>
          <img src='/fan.webp' alt='dscfd' className='w-[25px] h-[25px]'/>
          <div className='absolute z-10 bg-[#3f2900] border-[1px] border-[#8b8b8b] rounded-full h-[24px] w-[24px] flex justify-center items-center'>
          <img src='/dogs.webp' alt='sdfd' className='w-[10px]'/>
        </div>

         </div>
          </div>
            
                <div className="flex h-full flex-1 flex-col justify-center relative">
                  <div className='flex w-full flex-col justify-between h-full space-y-0.5'>
                    <h1 className="text-[16px] text-nowrap line-clamp-1 font-semibold">
                    Mine to earn
                    </h1>
                    <span className='flex text-secondary items-center w-fit text-[15px]'>
                   
                      <span className='text-[13px]'>
                        {user?.miningPower} profit per hour
                      </span>
                    </span>
                  </div>
                </div>
                <div className='w-fit flex items-center justify-end flex-wrap text-[14px] relative'>

                            <NavLink to='/farm'
                            className={`bg-[#36373c] text-[#fff] w-fit py-[10px] rounded-[30px] px-3 font-semibold ease-in duration-200`}
                          >
                           Mine
                          </NavLink>
        
                </div>
              </div>


    <div className="w-full bg-cards rounded-[16px] px-3 py-3 flex items-center justify-between space-x-1">
              
            
                <div className='flex items-center justify-center w-[50px] mr-1 p-1'>
                  <div className='w-[40px] flex justify-center rounded-full items-center text-center relative'>
                    <img src='/spin.svg' alt='dscfd' className='w-[45px]'/>

                    </div>
                </div>
            
                <div className="flex h-full flex-1 flex-col justify-center relative">
                  <div className='flex w-full flex-col justify-between h-full space-y-0.5'>
                    <h1 className="text-[16px] text-nowrap line-clamp-1 font-semibold">
                    Spin & earn
                    </h1>
                    <span className='flex text-secondary items-center w-fit text-[15px]'>
                   
                      <span className='text-[13px]'>
                        Spin to win rewards
                      </span>
                    </span>
                  </div>
                </div>
                <div className='w-fit flex items-center justify-end flex-wrap text-[14px] relative'>

                            <NavLink to='/spinearn'
                            className={`bg-[#36373c] text-[#fff] w-fit py-[10px] rounded-[30px] px-3 font-semibold ease-in duration-200`}
                          >
                          Spin now
                          </NavLink>
        
                </div>
              </div>

              

            



              <h3 className='font-medium text-[18px] pt-5 pl-2'>
       Special Tasks
          </h3>
          

          <WatchAdsTask/>
        
          <div
className="w-full bg-cards rounded-[16px] px-3 py-3 flex items-center justify-between space-x-1">
              
            
                <div className='flex items-center justify-center w-[40px]'>
                <div className='w-[40px] flex justify-center rounded-full items-center text-center relative'>
<MdLocationPin size={45} className='w-[45px] text-accent'/>



                    </div>
                </div>
            
                <div className="flex h-full flex-1 flex-col justify-center relative">
                  <div className='flex w-full flex-col justify-between h-full space-y-0.5'>
                    <h1 className="text-[15px] text-nowrap line-clamp-1 font-medium">
                  Daily Checkin
                    </h1>
                    <span className='flex text-secondary items-center w-fit text-[15px]'>
                   
                      <span className='text-[13px]'>
                        Claim daily rewards
                      </span>
                    </span>
                  </div>
                </div>
                <div className='w-fit flex items-center justify-end flex-wrap text-[14px] relative'>

                            <NavLink to='/checkin'
                            className={`bg-[#36373c] text-[#fff] w-fit py-[10px] rounded-[30px] px-3 font-semibold ease-in duration-200`}
                          >
                          Claim
                          </NavLink>
        
                </div>
              </div>

              <h3 className="font-medium text-lg pt-5 pl-2">Others</h3>
            <div className="w-full bg-cards px-3 rounded-xl py-3 flex items-center justify-between space-x-1">
              <div className="w-12 pr-2">
                <div className="flex items-center justify-center bg-gray-900 h-11 w-11 rounded-full p-1">
                  <img alt="Telegram" src="/telegramc.svg" className="w-6" />
                </div>
              </div>
              <div className="flex flex-1 h-full flex-col justify-center">
                <div className="w-full flex flex-col justify-between h-full space-y-1">
                  <h1 className="text-base font-medium truncate">Buff Community</h1>
                  <span className="flex text-gray-400 items-center text-sm">
                    Join Buff community
                  </span>
                </div>
              </div>
              <div className="flex items-center space-x-1 justify-end">
                <a className="bg-[#36373c] py-2 px-4 text-sm text-center font-semibold rounded-full text-white" href="https://t.me/BuffCoins">Join</a>
              </div>
            </div>
            
            <div className="w-full bg-cards px-3 rounded-xl py-3 flex items-center justify-between space-x-1">
              <div className="w-12 pr-2">
                <div className="flex items-center justify-center bg-gray-900 h-11 w-11 rounded-full p-1">
                  <MdAdsClick size={30} className="text-[#f3c025]" />
                </div>
              </div>
              <div className="flex flex-1 h-full flex-col justify-center">
                <div className="w-full flex flex-col justify-between h-full space-y-1">
                  <h1 className="text-base font-medium truncate">Ambassador Program</h1>
                  <span className="flex text-gray-400 items-center text-sm">
                    Make video and win USDT
                  </span>
                </div>
              </div>
              <div className="flex items-center space-x-1 justify-end">
                <button onClick={() => setAmbsProgram(true)} className="bg-[#36373c] py-2 px-4 text-sm text-center font-semibold rounded-full text-white">Join</button>
              </div>
            </div>

            {ambsprogram && (
            <div className="fixed flex bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#303030c4] flex-col justify-end items-center">
              <div className="w-full bg-divider shadowtop rounded-tl-[40px] rounded-tr-[40px] relative flex flex-col ease-in duration-300 transition-all justify-center">
                <div className="w-full flex taskbg rounded-tl-[40px] rounded-tr-[40px] mt-[2px] justify-center relative flex-col items-center space-y-3 p-4 pt-10 pb-10">
                  <button
                    onClick={() => setAmbsProgram(false)}
                    className="flex items-center justify-center h-[32px] w-[32px] rounded-full bg-[#383838] absolute right-6 top-4 text-center font-medium text-[16px]"
                  >
                    <IoClose size={20} className="text-[#9995a4]" />
                  </button>
                  <div className="w-full flex flex-col justify-center items-center">
                    <div className="pb-3">
                      <MdAdsClick size={50} className="text-[#f3c025]" />
                    </div>
                    <h3 className="font-semibold text-[24px]">Ambassador Program</h3>
                    <p className="text-[14px] font-medium text-center pb-3">
                      Create YT/TikTok/IG video and win up to 10,000 USDT!
                    </p>
                    <div className="text-[14px] font-medium text-gray-400 text-left w-full">
                      <p>Rules:</p>
                      <ol className="list-decimal list-inside">
                        <li>Add #buff #buffcoin #crypto #bitcoin to the title or description of your video.</li>
                        <li>
                          Include your unique code "66884826" in the title or description along with the hashtags.
                        </li>
                        <li>
                          Video should be focused on Buff; you can record your screen or just use the camera to record—whatever you prefer.
                        </li>
                        <li>
                          If your video is rejected 3 times in a row, you will be blacklisted from submitting more links for verification in this task.
                        </li>
                      </ol>
                      <p className="pt-2">Rewards:</p>
                      <p>
                        Earn between 1 USDT up to 10,000 USDT + 10k to 1M $BUFF coins. <br />
                        Your reward will be calculate based on your video views and your referrals.
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex justify-center items-center flex-col space-y-4">
                    <input
                      name="contentLink"
                      placeholder="Enter your content link"
                      className="w-full py-[12px] px-4 rounded-[12px] bg-[#444] text-white text-[14px]"
                    />
                    <button className="w-full py-[14px] px-6 text-nowrap flex items-center justify-center gap-2 text-center rounded-[12px] font-semibold text-[17px] bg-btn">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

    </div>
    )}
    {activeTab === 'Videos' && <div><VideoTasksMenu /></div>}
    {activeTab === 'Partners' && <div><PartnersTasksMenu /></div>}
    {activeTab === 'Ref Rewards' && <div><RefRewardsTasksMenu /></div>}
    </div>
    </div>
  )
}

export default Earns