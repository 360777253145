import React from 'react'
import LoginComp from '../../Components/adminComponents/LoginComp'

const NotAdmin236 = () => {
  return (
    <div className='w-full flex justify-center flex-col space-y-3 px-5 pt-3'>

        <LoginComp/>
  

  </div>
  )
}

export default NotAdmin236