import React from 'react'
import UserManagement from '../../Components/adminComponents/UserManagementPanel'


const Search = () => {
  return (
    <UserManagement/>
  )
}

export default Search