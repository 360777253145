import React from 'react';
import Animate from '../Components/Animate';
import RefRewards from './RefRewards';

const RefRewardsTasksMenu = () => {
  return (
    <>
      <Animate>
        <div className="w-full h-[70vh] scroller overflow-y-auto pt-2 pb-[30px]">
          <h3 className='font-medium text-[18px] pb-4'>
          Referrals Rewards
          </h3>

          <div className='w-full flex flex-col items-start'>
            <RefRewards/>
          </div>
        </div>
      </Animate>
    </>
  );
};

export default RefRewardsTasksMenu;
