export const images = {
    logo: '/stars.svg', // if using local images
    tapImage: "/stars.svg",
    loadingScreen: '/stars.svg', // if using URLs
  };
  
  export const texts = {
    projectName: "Buff",
    projectSymbol: "Buff",
  };

  export const urls = {
    appUrl: "https://app.buffcoins.com",
    botUsername: "BuffCoinBot",
    botChannel: "BuffCoins"

  }
  