import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useMongoUser } from '../context/mongoContext';
import { texts } from '../constants';
import { IoLogoPlaystation, IoMdArrowRoundForward, IoIosTrophy, IoIosLogOut, IoMdClose } from "react-icons/io";
import { GiLaurelsTrophy } from "react-icons/gi";
import { FiArrowRight } from "react-icons/fi";
import { HiOutlineLockClosed, HiOutlineLockOpen } from "react-icons/hi2";
import { TbPlaceholder } from "react-icons/tb";
import { FaPlay, FaFaceAngry } from 'react-icons/fa6';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';

// Import sound files
const shuffleSound = new Audio(process.env.PUBLIC_URL + "/sounds/puzzle.wav");
const gameOverSound = new Audio(process.env.PUBLIC_URL + "/sounds/gameover.mp3");
const winnerSound = new Audio(process.env.PUBLIC_URL + "/sounds/winner.mp3");

const logo = process.env.PUBLIC_URL + "/loader.svg";

const PuzzleGame = () => {
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [playsLeft, setPlaysLeft] = useState(5);
  const [timeLeft, setTimeLeft] = useState(180);
  const [level, setLevel] = useState(1);
  const { totalBalance, setBalance } = useMongoUser();
  const [puzzle, setPuzzle] = useState([1, 2, 3, 4, 5, 6, 7, 8, null]);
  const [isProgressModalOpen, setIsProgressModalOpen] = useState(false);
  const [adCountdown, setAdCountdown] = useState(0);
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const [isTimeUpModalOpen, setIsTimeUpModalOpen] = useState(false);
  const [isNoPlaysLeftModalOpen, setIsNoPlaysLeftModalOpen] = useState(false);
  const [isGameInProgress, setIsGameInProgress] = useState(false);
  const [isWinModalOpen, setIsWinModalOpen] = useState(false); // New state for Win Modal

  const navigate = useNavigate();

  const handleExit = () => {
    setIsExitModalOpen(true);
  };

  const confirmExit = () => {
    if (isGameInProgress) {
      setPlaysLeft((prev) => prev - 1);
    }
    navigate('/earn');
  };

  const cancelExit = () => {
    setIsExitModalOpen(false);
  };

  const updateBalance = (amount) => {
    const newBalance = totalBalance + amount;
    setBalance(newBalance);
  };

  const shufflePuzzle = useCallback(() => {
    const shuffled = [...puzzle]
      .sort(() => Math.random() - 0.5)
      .filter((item) => item !== null);
    setPuzzle([...shuffled, null]);
  }, [puzzle]);

  const handleTileClick = (index) => {
    const emptyIndex = puzzle.indexOf(null);
    const validMoves = [index - 1, index + 1, index - 3, index + 3];

    if (validMoves.includes(emptyIndex)) {
      const newPuzzle = [...puzzle];
      [newPuzzle[index], newPuzzle[emptyIndex]] = [newPuzzle[emptyIndex], newPuzzle[index]];
      setPuzzle(newPuzzle);

      shuffleSound.play();

      if (JSON.stringify(newPuzzle) === JSON.stringify([1, 2, 3, 4, 5, 6, 7, 8, null])) {
        winnerSound.play();
        setIsWinModalOpen(true); // Open Win Modal
        updateBalance(500);
        handleGameEnd();
      }
    }
  };

  const handleGameEnd = useCallback(() => {
    setIsGameStarted(false);
    setIsGameInProgress(false);
    setTimeLeft(180);
    if (playsLeft === 0) {
      setAdCountdown(24 * 60 * 60);
    }
  }, [playsLeft]);

  useEffect(() => {
    let timer;
    if (isGameStarted && timeLeft > 0) {
      timer = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
    }
    if (timeLeft === 0) {
      gameOverSound.play();
      setIsTimeUpModalOpen(true);
      handleGameEnd();
    }
    return () => clearInterval(timer);
  }, [isGameStarted, timeLeft, handleGameEnd]);

  const startGame = useCallback(() => {
    if (playsLeft > 0) {
      shufflePuzzle();
      setIsGameStarted(true);
      setIsGameInProgress(true);
      setTimeLeft(180);
      setPlaysLeft((prev) => prev - 1);
    } else {
      setIsNoPlaysLeftModalOpen(true);
    }
  }, [playsLeft, shufflePuzzle]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isGameInProgress) {
        event.preventDefault();
        event.returnValue = '';
        setPlaysLeft((prev) => prev - 1);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      if (isGameInProgress) {
        handleGameEnd();
      }
    };
  }, [isGameInProgress, handleGameEnd]);

  useEffect(() => {
    let timer;
    if (adCountdown > 0) {
      timer = setInterval(() => {
        setAdCountdown((prevCountdown) => {
          if (prevCountdown > 0) {
            return prevCountdown - 1;
          } else {
            clearInterval(timer);
            setPlaysLeft(5); // Reset plays when countdown reaches 0
            return 0;
          }
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [adCountdown]);

  const renderWinModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-80 px-6 flex items-center justify-center z-50">
            <div className="bg-[#303030] p-6 rounded-lg shadow-xl flex justify-center flex-col items-center">
            <div className="w-full items-center justify-center flex flex-col space-y-2">
              <IoCheckmarkCircleSharp size={32} className='text-accent'/>
              <p className='font-medium'>Congratulations!</p>
            </div>
              <h3 className="font-medium text-[24px] text-[#ffffff] pt-2 pb-2">
              <span className='text-accent'>+ </span> {texts.projectSymbol}
            </h3>
              <p className="pb-6 text-[#bfbfbf] text-[15px] w-full text-center">
              Keep playing! something huge is coming! Get more {texts.projectSymbol} now! 
            </p>
              <div className="w-full flex justify-center">
              <button 
                onClick={() => setIsWinModalOpen(false)}
                className="bg-btn text-[#000] w-fit py-[10px] px-6 flex items-center justify-center text-center rounded-[12px] font-medium text-[16px]"
              >
                Continue
              </button>
              </div>
            </div>
          </div>
  );

  const renderProgressModal = () => (
    <div className="fixed flex bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#303030c4] flex-col justify-end items-center">  
      <div className="w-full bg-divider shadowtop rounded-tl-[40px] rounded-tr-[40px] relative flex flex-col ease-in duration-300 transition-all justify-center">
        <div className="w-full flex taskbg rounded-tl-[40px] rounded-tr-[40px] mt-[2px] justify-center relative flex-col items-center space-y-3 p-4 pt-20 pb-20">
          <button
            onClick={() => setIsProgressModalOpen(false)}
            className="flex items-center justify-center h-[32px] w-[32px] rounded-full bg-[#383838] absolute right-6 top-4 text-center font-medium text-[16px]"
          >
            <IoMdClose size={20} className="text-[#9995a4]"/>
          </button>

          <h1 className="mb-8 mt-4 text-2xl font-bold text-white">My Progress</h1>

          <div className="w-full grid grid-cols-2 gap-4">
            {[1, 2, 3, 4].map((lvl) => (
              <div
                key={lvl}
                className={`relative rounded-2xl ${
                  lvl <= level ? "bg-[#3a3b6b]" : "bg-[#2a2b5b]"
                } py-2 px-3 transition-all duration-200 hover:scale-[1.02]`}
              >
                <div className="mb-2 flex items-center justify-between">
                  <span className="text-sm font-medium text-white">Level {lvl}</span>
                  <div className="pl-2">
                    {lvl <= level ? (
                      <GiLaurelsTrophy className="text-yellow-400" size={28} />
                    ) : (
                      <GiLaurelsTrophy className="text-white/50" size={28} />
                    )}
                  </div>
                </div>
                <p className="text-sm text-white/60">
                  {lvl <= level ? (
                    <>
                      Current level
                      <HiOutlineLockOpen className="inline-block ml-6" size={20} />
                    </>
                  ) : (
                    <>
                      Not Unlocked
                      <HiOutlineLockClosed className="inline-block ml-4" size={20} />
                    </>
                  )}
                </p>

                {lvl === level && (
                  <div className="mt-4 flex items-center space-x-2">
                    <IoLogoPlaystation className="text-white/90" size={16} />
                    <span className="text-xs flex font-medium text-white/90">Play </span>
                    <FiArrowRight className="text-white/90" size={16} />
                  </div>
                )}

                {lvl !== level && (
                  <div className="mt-4">
                    <span className="text-sm text-white/40">---</span>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  const renderNoPlaysLeftModal = useCallback(() => (
    <div className="fixed flex bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#303030c4] flex-col justify-end items-center">  
      <div className="w-full bg-divider shadowtop rounded-tl-[40px] rounded-tr-[40px] relative flex flex-col ease-in duration-300 transition-all justify-center">
        <div className="w-full flex taskbg rounded-tl-[40px] rounded-tr-[40px] mt-[2px] justify-center relative flex-col items-center space-y-3 p-4 pt-20 pb-10">
          <button 
            onClick={() => setIsNoPlaysLeftModalOpen(false)}
            className="flex items-center justify-center h-[32px] w-[32px] rounded-full bg-[#383838] absolute right-6 top-4 text-center font-medium text-[16px]"
          >
            <IoMdClose size={20} className="text-[#9995a4]"/>
          </button>

          <div className='w-full bg-cards rounded-[16px] py-6 relative px-4 flex flex-col justify-center items-center'>
            <TbPlaceholder size={34} className='text-accent mb-4'/>

            <p className="text-[#e5e5e5] font-medium px-8 text-[14px] w-full text-center">
              You have no plays left for today!
            </p>
            <span className="text-[34px] font-semibold">
              {Math.floor(adCountdown / 3600)}h {Math.floor((adCountdown % 3600) / 60)}m {adCountdown % 60}s
            </span>
          </div>

          <div className="w-full flex justify-between items-center gap-2 px-4">
            <div className="w-[40%] h-[2px] bg-cards2"></div>
            <span className="text-nowrap">OR</span>
            <div className="w-[40%] h-[2px] bg-cards2"></div>
          </div>

          <div className="w-full flex flex-col justify-center items-center">
            <h3 className="font-semibold text-[24px]">
              Watch Ad
            </h3>
            <p className="text-[14px] font-medium">
              & get one more free play for more rewards
            </p>
          </div>

          <div className="w-full flex justify-center items-center flex-col space-y-4">
            <button 
              className='w-full bg-btn py-[18px] px-6 text-nowrap flex items-center justify-center gap-2 text-center rounded-[12px] font-semibold text-[17px]'
            >
              <span className="contents"><FaPlay /> Watch Ad for free play</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  ), [adCountdown]);

  const renderExitModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center">
      <div className="bg-[#3b3a70] text-white px-6 py-4 rounded-lg shadow-lg flex flex-col items-center justify-center space-y-2">
      <IoIosLogOut size={30}/>
        <span className="font-medium text-[15px] w-full text-center pb-2">Are you sure you want to exit?</span>
        <div className="w-full flex justify-center space-x-4 items-center">
          <button
            onClick={confirmExit}
            className="rounded-lg py-2 px-3 bg-[#c586e9] text-[#fff] text-center font-medium text-[15px] w-[45%]"
          >
            Yes, Exit
          </button>
          <button
            onClick={cancelExit}
            className="rounded-lg py-2 px-3 bg-white/70 text-black font-medium text-center text-[15px] w-[45%]"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );

  const renderTimeUpModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-80 px-6 flex items-center justify-center z-50">
      <div className="bg-[#303030] p-6 rounded-lg shadow-xl flex justify-center flex-col items-center">
        <div className="w-full items-center justify-center flex flex-col space-y-2">
        <FaFaceAngry size={32} className='text-accent'/>
          <p className='font-medium text-lg pt-2 pb-2'>Time's up!</p>
        </div>
        <p className="pb-4 text-[#bfbfbf] text-[15px] w-full text-center">You didn't solve the puzzle in time.</p>
        <button
          onClick={() => setIsTimeUpModalOpen(false)}
          className="bg-btn text-[#000] w-fit py-[10px] px-6 flex items-center justify-center text-center rounded-[12px] font-medium text-[16px]"
        >
          Continue
        </button>
      </div>
    </div>
  );

  return (
    <div className="h-screen w-screen bg-black text-white flex flex-col items-center justify-center relative">
      <div className="w-full px-8">
        <div className="w-full flex justify-between">
          <div className="w-fit mt-[-4px] px-4 py-1.5 border border-[#7d80a8cc] bg-[#635e96ac] rounded-xl flex justify-center items-center space-x-2">
            <img src={logo || "/placeholder.svg"} alt="Logo" className="w-4 h-4" />
            <span className="font-semibold text-[14px]">{totalBalance}</span>
          </div>
          <div className="flex items-center space-x-2">
            <button onClick={handleExit}
            className="w-fit mt-[-4px] px-1.5 py-[8px] border border-[#7d80a8cc] bg-[#635e96ac]/30 rounded-xl flex justify-center items-center space-x-1">
              <IoIosLogOut />
              <span className="font-semibold text-[12px]">Exit</span>
            </button>
          </div>
        </div>
        <div className="w-full flex items-center justify-between border-[#382f64] border-[1px] py-2 px-3 mt-6 rounded-[10px] bg-[#332669]">
          <div className="bg-gradient-to-r from-[#fa8bd9] to-[#6a95fe] rounded-[8px] text-primary py-[16px] px-3 w-full flex space-x-1 justify-center text-center text-[13px] font-medium items-center">
            <span>Puzzle Mini Game</span>
          </div>
        </div>
      </div>

      {isProgressModalOpen && renderProgressModal()}
      {isExitModalOpen && renderExitModal()}
      {isTimeUpModalOpen && renderTimeUpModal()}
      {isNoPlaysLeftModalOpen && renderNoPlaysLeftModal()}
      {isWinModalOpen && renderWinModal()} {/* Render Win Modal */}

      {/* Top Row */}
      <div className="w-full flex justify-between px-8 mt-4 items-center">
        <button
          className="bg-[#3e3071] border-[1px] border-[#493e7a] rounded-[8px] text-primary py-[10px] px-4 w-fit space-x-1.5 text-[13px] font-medium flex justify-center text-center items-center">
          <IoIosTrophy className="text-[#ca8a04]" />
          <span>Level {level}</span>
        </button>
        <button
          className="bg-[#c586e999] border-[1px] border-[#493e7a] rounded-[8px] text-primary py-[10px] px-4 w-fit space-x-1.5 text-[13px] font-medium flex justify-center text-center items-center"
          onClick={() => setIsProgressModalOpen(true)}
        >
          <span>Progress</span>
          <IoMdArrowRoundForward />
        </button>
      </div>

      {/* Second Row */}
      <div className="w-full flex justify-between px-8 mt-2 items-center">
        <span className="text-sm">Plays left: {playsLeft}</span>
        <span className="text-sm">
          Time left: {Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? '0' : ''}{timeLeft % 60}
        </span>
      </div>

      {/* Puzzle Grid */}
      <div
        className={`grid grid-cols-3 bg-gray-200 p-1 rounded-lg gap-1 mt-4 mb-10 ${
          !isGameStarted ? "pointer-events-none blur-sm" : ""
        }`}
      >
        {puzzle.map((num, index) => (
          <div
            key={index}
            onClick={() => handleTileClick(index)}
            className={`w-24 h-24 flex items-center justify-center text-xl font-bold rounded-md cursor-pointer ${
              num
                ? [
                    "bg-red-500",
                    "bg-green-500",
                    "bg-blue-500",
                    "bg-yellow-500",
                    "bg-purple-500",
                    "bg-pink-500",
                    "bg-orange-500",
                    "bg-teal-500",
                  ][num - 1]
                : "bg-gray-200"
            }`}
          >
            {num}
          </div>
        ))}
      </div>

      {/* Start Button */}
      {!isGameStarted && (
        <div className="absolute inset-0 bg-black bg-opacity-80 flex items-center justify-center">
          <button
            onClick={startGame}
            className="bg-blue-500 text-white border-[1px] border-blue-400 flex items-center justify-center w-[100px] h-[100px] space-x-1 rounded-full transition-colors duration-200 font-semibold"
          >
            <IoLogoPlaystation />
            <span className="text-[18px]">Start </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default PuzzleGame;