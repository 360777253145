import React from 'react';
import Animate from '../Components/Animate';
import Partners from './Partners';

const PartnersTasksMenu = () => {
  return (
    <>
      <Animate>
        <div className="w-full h-[70vh] scroller overflow-y-auto pt-2 pb-[30px]">
          <h3 className='font-medium text-[18px] pb-4'>
          Partners Tasks
          </h3>

          <div className='w-full flex flex-col items-start'>
            <Partners/>
          </div>
        </div>
      </Animate>
    </>
  );
};

export default PartnersTasksMenu;
